import { createAction, props } from '@ngrx/store';
import { IBulkResponseRecord, IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import { IAddState, IBulkEditStates, IState, IStateTableQuery, IValidationResult } from './states.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';

export enum ActionTypes {
  STATES_DATA_LOADING = '[STATES] STATE DATA LOADING',
  STATES_DATA_LOADED = '[STATES] STATE DATA LOADED',
  STATE_DETAIL_DATA_LOADING = '[STATES] STATE DETAIL DATA LOADING',
  STATE_DETAIL_DATA_LOADED = '[STATES] STATE DETAIL DATA LOADED',
  ADD_STATE = '[STATES] ADD STATE',
  ADD_STATE_COMPLETED = '[STATES] ADD STATE COMPLETED',
  EDIT_STATE = '[STATES] EDIT STATE',
  EDIT_STATE_COMPLETED = '[STATES] EDIT STATE COMPLETED',
  BULK_EDIT_STATES = '[STATES] BULK EDIT STATE',
  BULK_EDIT_STATES_COMPLETED = '[STATES] BULK EDIT STATE COMPLETED',
  DELETE_STATE = '[STATES] DELETE STATE',
  DELETE_STATE_COMPLETED = '[STATES] DELETE STATE COMPLETED',
  BULK_DELETE_STATE = '[STATES] BULK DELETE STATE',
  BULK_DELETE_STATE_COMPLETED = '[STATES] BULK DELETE STATE COMPLETED',
  VALIDATE_STATE = '[STATES] VALIDATE STATE',
  VALIDATE_STATE_COMPLETED = '[STATES] VALIDATE STATE COMPLETED',
  FETCH_ERROR = '[STATES] FETCH ERROR',
}

export const statesDataLoading = createAction(
  ActionTypes.STATES_DATA_LOADING,
  props<{ tableQuery: IStateTableQuery }>(),
);
export const statesDataLoaded = createAction(
  ActionTypes.STATES_DATA_LOADED,
  props<{ payload: IGetManyResponse<IState> }>(),
);

export const statesDetailDataLoading = createAction(
  ActionTypes.STATE_DETAIL_DATA_LOADING,
  props<{ id: number; isEdit?: boolean }>(),
);

export const statesDetailDataLoaded = createAction(
  ActionTypes.STATE_DETAIL_DATA_LOADED,
  props<{ payload: IState; isEdit?: boolean }>(),
);

export const addState = createAction(ActionTypes.ADD_STATE, props<{ state: IAddState; issuer: IIssuer | null }>());

export const addStateCompleted = createAction(ActionTypes.ADD_STATE_COMPLETED, props<{ payload: IState }>());

export const editState = createAction(
  ActionTypes.EDIT_STATE,
  props<{
    state: IAddState;
    id: number;
    issuerAndReason: IIssuerAndReason;
  }>(),
);

export const editStateCompleted = createAction(ActionTypes.EDIT_STATE_COMPLETED, props<{ payload: IState }>());

export const bulkEditStates = createAction(
  ActionTypes.BULK_EDIT_STATES,
  props<{ states: IBulkEditStates[]; issuerAndReason: IIssuerAndReason }>(),
);

export const bulkEditStatesCompleted = createAction(
  ActionTypes.BULK_EDIT_STATES_COMPLETED,
  props<{ payload: IBulkResponseRecord<IState>[] }>(),
);

export const deleteState = createAction(
  ActionTypes.DELETE_STATE,
  props<{ id: number; issuerAndReason: IIssuerAndReason }>(),
);

export const deleteStateCompleted = createAction(ActionTypes.DELETE_STATE_COMPLETED, props<{ payload: IState }>());

export const bulkDeleteState = createAction(
  ActionTypes.BULK_DELETE_STATE,
  props<{ stateIds: number[]; issuerAndReason: IIssuerAndReason }>(),
);

export const bulkDeleteStateCompleted = createAction(
  ActionTypes.BULK_DELETE_STATE_COMPLETED,
  props<{ payload: IBulkResponseRecord<IState>[] }>(),
);

export const validateState = createAction(
  ActionTypes.VALIDATE_STATE,
  props<{ stateIds: number[]; issuer: IIssuer | null }>(),
);

export const validateStateCompleted = createAction(
  ActionTypes.VALIDATE_STATE_COMPLETED,
  props<{ payload: IValidationResult[] }>(),
);

export const fetchError = createAction(ActionTypes.FETCH_ERROR, props<{ payload: object }>());

import { ActionReducer, createReducer, on } from '@ngrx/store';
import * as RuleActions from './rule.actions';
import { IRule, IRuleState } from './rule.model';
import * as _ from 'lodash';

export const initialState: IRuleState = {
  rulesDataLoading: false,
  rulesDataLoaded: false,
  rulesDetailDataLoading: false,
  rulesDetailDataLoaded: false,
  addRulesLoading: false,
  addRulesLoaded: false,
  editRulesLoading: false,
  editRulesLoaded: false,
  deleteRulesLoading: false,
  deleteRulesLoaded: false,
  bulkEditRulesLoading: false,
  bulkEditRulesLoaded: false,
  rulesData: null,
};

export const ruleReducer: ActionReducer<IRuleState> = createReducer(
  initialState,
  on(RuleActions.RulesDataLoading, (state: IRuleState) => ({
    ...state,
    rulesDataLoading: true,
    rulesDataLoaded: false,
  })),
  on(RuleActions.RulesDataLoaded, (state: IRuleState, { payload }) => ({
    ...state,
    rulesDataLoading: false,
    rulesDataLoaded: true,
    rulesData: payload.data,
  })),
  on(RuleActions.RulesDetailDataLoading, (state: IRuleState) => ({
    ...state,
    rulesDetailDataLoading: true,
    rulesDetailDataLoaded: false,
  })),
  on(RuleActions.RulesDetailDataLoaded, (state: IRuleState, { payload }) => ({
    ...state,
    rulesDetailDataLoading: false,
    rulesDetailDataLoaded: true,
    rulesData: _.cloneDeep(state.rulesData || []).map((rule: IRule) => {
      if (rule.id === payload.id) {
        rule.logbooks = payload.logbooks;
      }

      return rule;
    }),
  })),
  on(RuleActions.AddRule, (state: IRuleState) => ({
    ...state,
    addRulesLoading: true,
    addRulesLoaded: false,
  })),
  on(RuleActions.AddRuleCompleted, (state: IRuleState) => ({
    ...state,
    addRulesLoading: false,
    addRulesLoaded: true,
  })),
  on(RuleActions.EditRule, (state: IRuleState) => ({
    ...state,
    addRulesLoading: true,
    addRulesLoaded: false,
  })),
  on(RuleActions.EditRuleCompleted, (state: IRuleState) => ({
    ...state,
    editRulesLoading: false,
    editRulesLoaded: true,
  })),
  on(RuleActions.DeleteRule, (state: IRuleState) => ({
    ...state,
    deleteRulesLoading: true,
    deleteRulesLoaded: false,
  })),
  on(RuleActions.DeleteRuleCompleted, (state: IRuleState) => ({
    ...state,
    deleteRulesLoading: false,
    deleteRulesLoaded: true,
  })),
  on(RuleActions.BulkEditRule, (state: IRuleState) => ({
    ...state,
    bulkEditRulesLoading: true,
    bulkEditRulesLoaded: false,
  })),
  on(RuleActions.BulkEditRuleCompleted, (state: IRuleState) => ({
    ...state,
    bulkEditRulesLoading: false,
    bulkEditRulesLoaded: true,
  })),
);

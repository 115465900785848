import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import { ILogbooksCRUD, IRuleType } from '../../../../shared/component/filter/filter.class';
import { IActivityType } from '../../activity-type/activity-type.model';
import { IState } from '../../states/states.model';

export interface IAddRule {
  logbooks: number[];
  name: string;
  ruleType: number;
  states: number[] | null;
  nextStates: number[] | null;
  activityTypes: number[] | null;
  isActive: boolean;
  deselectedLogbooks?: number[];
}

export interface IRuleDetail {
  states: (IState & IOrder)[] | null;
  nextStates: (IState & IOrder)[] | null;
  activityTypes: (IActivityType & IOrder)[] | null;
}

export interface IRule {
  id: number;
  reason: string | null;
  isAllLogbooks: boolean;
  name: string;
  isActive: boolean;
  ruleType: IRuleType;
  createdBy: number;
  createdAt: string;
  logbookCount: number;
  details: IRuleDetail;
  logbooks?: ILogbooksCRUD[];
  formattedLogbooks?: string;
}

export interface IBulkEditRule extends Pick<IRule, 'id' | 'isActive'>, Pick<IIssuerAndReason, 'reason'> {}

export interface IRuleState {
  rulesDataLoading: boolean;
  rulesDataLoaded: boolean;
  rulesDetailDataLoading: boolean;
  rulesDetailDataLoaded: boolean;
  addRulesLoading: boolean;
  addRulesLoaded: boolean;
  editRulesLoading: boolean;
  editRulesLoaded: boolean;
  deleteRulesLoading: boolean;
  deleteRulesLoaded: boolean;
  bulkEditRulesLoading: boolean;
  bulkEditRulesLoaded: boolean;
  rulesData: IRule[] | null;
}

export enum ERuleType {
  STATE_RULE = 'state_rule',
  ACTIVITY_TYPE_RULE = 'activity_type_rule',
  MULTIPLE_STATES_RULE = 'multiple_states_rule',
}

export interface IOrder {
  ordering: number;
}

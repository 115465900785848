import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../logbook.reducer';
import { RuleService } from './rule.service';
import * as RuleActions from './rule.actions';
import { map, of, switchMap, tap } from 'rxjs';
import { ServiceUtilities } from '../../../../shared/helper/service-utilities';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { ERuleType, IAddRule, IBulkEditRule, IRule } from './rule.model';
import { catchError } from 'rxjs/operators';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import * as AppActions from '../../../app/actions';
import { ERuleErrorTypes, IRuleTableQuery } from '../../../../view/settings/state-rule-and-automation/rules/rule.model';
import * as _ from 'lodash';
import { StatesService } from '../../states/states.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class RuleEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: RuleService,
    private readonly translate: TranslateService,
  ) {}

  getRuleData = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.RULE_DATA_LOADING),
      switchMap((payload: { tableQuery: IRuleTableQuery }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const body: Record<string, string | number | number[]> = {
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          ordering: `${payload.tableQuery.sort?.type ?? ''}${ServiceUtilities.underscorePreservingSnakeCase(
            payload.tableQuery.sort?.column,
          )}`,
          logbookIds: payload.tableQuery.logbookIds,
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(!_.isNil(payload.tableQuery.isActive) ? { isActive: Number(payload.tableQuery.isActive) } : {}),
          ...(payload.tableQuery.ruleTypes && { ruleTypes: payload.tableQuery.ruleTypes }),
        };

        return this.service.getRules(body).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((response: IGetManyResponse<IRule>) => {
            response.data = StatesService.formatTableData(
              response.data,
              `(${this.translate.instant('home.logbook.archived')})`,
            ).map((row: IRule) => {
              if (row.ruleType.name === ERuleType.MULTIPLE_STATES_RULE) {
                Object.assign(row.details, { states: _.orderBy(row.details.states, 'ordering') });
              }

              return row;
            });

            return RuleActions.RulesDataLoaded({ payload: response });
          }),
          catchError((error) => of(RuleActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(RuleActions.FetchError({ payload: error }))),
    ),
  );

  getRuleDetailData = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.RULE_DETAIL_DATA_LOADING),
      switchMap((payload: { id: number; isEdit: boolean }) => {
        this.store.dispatch(payload.isEdit ? new AppActions.ShowLoader() : new AppActions.ShowTopLoader());

        return this.service.getRuleDetail(payload.id).pipe(
          tap(() => {
            this.store.dispatch(payload.isEdit ? new AppActions.HideLoader() : new AppActions.HideTopLoader());
          }),
          map((response: IBaseOneResponse<IRule>) =>
            RuleActions.RulesDetailDataLoaded({ payload: response.data, isEdit: payload.isEdit }),
          ),
          catchError((error) => of(RuleActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(RuleActions.FetchError({ payload: error }))),
    ),
  );

  addRule = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.ADD_RULE),
      switchMap((payload: { rule: IAddRule; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addRule(payload.rule, payload.issuer).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((response: IBaseOneResponse<IRule>) => RuleActions.AddRuleCompleted({ payload: response.data })),
          catchError((error) =>
            of(
              RuleActions.FetchError({
                payload: { name: _.get(error, 'error.data.name'), type: _.get(error, 'error.data.error') },
              }),
            ),
          ),
        );
      }),
      catchError((error) => of(RuleActions.FetchError({ payload: error }))),
    ),
  );

  editRule = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.EDIT_RULE),
      switchMap((payload: { rule: IAddRule; id: number; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editRule(payload.rule, payload.id, payload.issuer).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((response: IBaseOneResponse<IRule>) => RuleActions.EditRuleCompleted({ payload: response.data })),
          catchError((error) => {
            const errorType: string | undefined = _.get(error, 'error.data.error');

            if (errorType === ERuleErrorTypes.COLLISION) {
              return of(
                RuleActions.FetchError({
                  payload: { name: _.get(error, 'error.data.name'), type: errorType },
                }),
              );
            }

            return of(RuleActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => of(RuleActions.FetchError({ payload: error }))),
    ),
  );

  deleteRule = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.DELETE_RULE),
      switchMap((payload: { ruleIds: number[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteRule(payload.ruleIds, payload.issuerAndReason).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((response: IGetManyResponse<IBulkResponseRecord<IRule>>) =>
            RuleActions.DeleteRuleCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            return of(RuleActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => {
        return of(RuleActions.FetchError({ payload: error }));
      }),
    ),
  );

  bulkEditRule = createEffect(() =>
    this.actions$.pipe(
      ofType(RuleActions.ActionTypes.BULK_EDIT_RULE),
      switchMap((payload: { rules: IBulkEditRule[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditRule(payload.rules, payload.issuerAndReason).pipe(
          tap(() => this.store.dispatch(new AppActions.HideLoader())),
          map((response: IGetManyResponse<IBulkResponseRecord<IRule>>) =>
            RuleActions.BulkEditRuleCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            const errorType: string | undefined = _.get(error, 'error.data.error');

            if (errorType === ERuleErrorTypes.COLLISION) {
              return of(
                RuleActions.FetchError({
                  payload: { name: _.get(error, 'error.data.name'), type: errorType },
                }),
              );
            }

            return of(RuleActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => of(RuleActions.FetchError({ payload: error }))),
    ),
  );
}

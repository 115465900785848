import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { LogbookAppState } from '../../../logbook.reducer';
import { AutomationService } from './automation.service';
import * as AutomationActions from './automation.actions';
import { map, of, switchMap, tap } from 'rxjs';
import { ServiceUtilities } from '../../../../shared/helper/service-utilities';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IAddAutomation, IAutomation, IAutomationTableData, IBulkEditAutomation } from './automation.model';
import { catchError } from 'rxjs/operators';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import * as AppActions from '../../../app/actions';
import {
  EAutomationErrorTypes,
  IAutomationTableQuery,
} from '../../../../view/settings/state-rule-and-automation/automations/automations.model';
import * as _ from 'lodash';
import { StatesService } from '../../states/states.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AutomationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<LogbookAppState>,
    private readonly service: AutomationService,
    private readonly translate: TranslateService,
  ) {}

  getAutomationData = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.AUTOMATION_DATA_LOADING),
      switchMap((payload: { tableQuery: IAutomationTableQuery }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const body: Record<string, string | number | number[]> = {
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          ordering: `${payload.tableQuery.sort?.type ?? ''}${ServiceUtilities.underscorePreservingSnakeCase(
            payload.tableQuery.sort?.column,
          )}`,
          logbookIds: payload.tableQuery.logbookIds,
          ...(payload.tableQuery.search && { search: payload.tableQuery.search }),
          ...(!_.isNil(payload.tableQuery.isActive) ? { isActive: Number(payload.tableQuery.isActive) } : {}),
          ...(payload.tableQuery.automationTypes && { automationTypes: payload.tableQuery.automationTypes }),
        };

        return this.service.getAutomations(body).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IAutomation>) => {
            response.data = StatesService.formatTableData<IAutomation>(
              response.data,
              `(${this.translate.instant('home.logbook.archived')})`,
            ).map((row): IAutomationTableData => {
              return {
                ...row,
                formattedAutomationType: this.translate.instant(`filterCard.automationType.${row.automationType.name}`),
              };
            });

            return AutomationActions.AutomationsDataLoaded({ payload: response });
          }),
          catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );

  getAutomationDetailData = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.AUTOMATION_DETAIL_DATA_LOADING),
      switchMap((payload: { id: number; isEdit: boolean }) => {
        this.store.dispatch(payload.isEdit ? new AppActions.ShowLoader() : new AppActions.ShowTopLoader());

        return this.service.getAutomationDetail(payload.id).pipe(
          tap(() => {
            this.store.dispatch(payload.isEdit ? new AppActions.HideLoader() : new AppActions.HideTopLoader());
          }),
          map((response: IBaseOneResponse<IAutomation>) =>
            AutomationActions.AutomationsDetailDataLoaded({ payload: response.data, isEdit: payload.isEdit }),
          ),
          catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );

  addAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.ADD_AUTOMATION),
      switchMap((payload: { automation: IAddAutomation; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addAutomation(payload.automation, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IAutomation>) =>
            AutomationActions.AddAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            const errorType: string | undefined = _.get(error, 'error.data.error');

            if (errorType === EAutomationErrorTypes.COLLISION) {
              return of(
                AutomationActions.FetchError({
                  payload: { name: _.get(error, 'error.data.name'), type: errorType },
                }),
              );
            }

            return of(AutomationActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );

  editAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.EDIT_AUTOMATION),
      switchMap((payload: { automation: IAddAutomation; id: number; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editAutomation(payload.automation, payload.id, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IAutomation>) =>
            AutomationActions.EditAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            const errorType: string | undefined = _.get(error, 'error.data.error');

            if (errorType === EAutomationErrorTypes.COLLISION) {
              return of(
                AutomationActions.FetchError({
                  payload: { name: _.get(error, 'error.data.name'), type: errorType },
                }),
              );
            }

            return of(AutomationActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );

  deleteAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.DELETE_AUTOMATION),
      switchMap((payload: { automationIds: number[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteAutomation(payload.automationIds, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IAutomation>>) =>
            AutomationActions.DeleteAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );

  bulkEditAutomation = createEffect(() =>
    this.actions$.pipe(
      ofType(AutomationActions.ActionTypes.BULK_EDIT_AUTOMATION),
      switchMap((payload: { automations: IBulkEditAutomation[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditAutomation(payload.automations, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IAutomation>>) =>
            AutomationActions.BulkEditAutomationCompleted({ payload: response.data }),
          ),
          catchError((error) => {
            const errorType: string | undefined = _.get(error, 'error.data.error');

            if (errorType === EAutomationErrorTypes.COLLISION) {
              return of(
                AutomationActions.FetchError({
                  payload: { name: _.get(error, 'error.data.name'), type: errorType },
                }),
              );
            }

            return of(AutomationActions.FetchError({ payload: error }));
          }),
        );
      }),
      catchError((error) => of(AutomationActions.FetchError({ payload: error }))),
    ),
  );
}

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, tap } from 'rxjs';
import * as AppActions from '../../app/actions';
import { Store } from '@ngrx/store';
import * as LogbookAppReducer from '../../logbook.reducer';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../shared/model/interface/crud-response-interface.model';
import { StatesService } from './states.service';
import * as StateActions from './states.actions';
import { IIssuer } from '../../../shared/component/issuer/issuer.model';
import {
  IAddState,
  IBulkEditStates,
  IState,
  IStateTableQuery,
  IValidatePayload,
  IValidationResult,
} from './states.model';
import { IIssuerAndReason } from '../../../shared/component/before-action-preparer/before-action-preparer.model';
import { TranslateService } from '@ngx-translate/core';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import * as _ from 'lodash';

@Injectable()
export class StatesEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly service: StatesService,
    private readonly store: Store<LogbookAppReducer.LogbookAppState>,
    private readonly translate: TranslateService,
  ) {}

  getStateData = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.STATES_DATA_LOADING),
      switchMap((payload: { tableQuery: IStateTableQuery }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const body: Record<string, string | number | number[]> = {
          page: payload.tableQuery.page,
          rowsPerPage: payload.tableQuery.rowsPerPage,
          logbookIds: payload.tableQuery.logbookIds,
          ordering: `${payload.tableQuery.sort?.type ?? ''}${ServiceUtilities.underscorePreservingSnakeCase(
            payload.tableQuery.sort?.column,
          )}`,
          ...(payload.tableQuery.search ? { search: payload.tableQuery.search } : {}),
          ...(!_.isNil(payload.tableQuery.isActive) ? { isActive: Number(payload.tableQuery.isActive) } : {}),
        };

        return this.service.getStates(body).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IState>) => StateActions.statesDataLoaded({ payload: response })),
          catchError((error) => of(StateActions.fetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ payload: error }))),
    ),
  );

  getStateDetailData = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.STATE_DETAIL_DATA_LOADING),
      switchMap((payload: { id: number; isEdit: boolean }) => {
        this.store.dispatch(payload.isEdit ? new AppActions.ShowLoader() : new AppActions.ShowTopLoader());

        return this.service.getStateDetail(payload.id).pipe(
          tap(() => {
            this.store.dispatch(payload.isEdit ? new AppActions.HideLoader() : new AppActions.HideTopLoader());
          }),
          map((response: IBaseOneResponse<IState>) =>
            StateActions.statesDetailDataLoaded({ payload: response.data, isEdit: payload.isEdit }),
          ),
          catchError((error) => of(StateActions.fetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ payload: error }))),
    ),
  );

  addState = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.ADD_STATE),
      switchMap((payload: { state: IAddState; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.addState(payload.state, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IState>) => StateActions.addStateCompleted({ payload: response.data })),
          catchError((error) => of(StateActions.fetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ payload: error }))),
    ),
  );

  editState = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.EDIT_STATE),
      switchMap((payload: { state: IAddState; id: number; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.editState(payload.state, payload.id, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IBaseOneResponse<IState>) => StateActions.editStateCompleted({ payload: response.data })),
          catchError((error) => of(StateActions.fetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ payload: error }))),
    ),
  );

  bulkEditStates = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.BULK_EDIT_STATES),
      switchMap((payload: { states: IBulkEditStates[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.bulkEditStates(payload.states, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IState>>) =>
            StateActions.bulkEditStatesCompleted({ payload: response.data }),
          ),
          catchError((error) => of(StateActions.fetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ payload: error }))),
    ),
  );

  deleteState = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.DELETE_STATE),
      switchMap((payload: { id: number; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteState([payload.id], payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IState>>) =>
            StateActions.deleteStateCompleted({ payload: response.data[0].data[0] }),
          ),
          catchError((error) => of(StateActions.fetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ payload: error }))),
    ),
  );

  bulkDeleteActivityType = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.BULK_DELETE_STATE),
      switchMap((payload: { stateIds: number[]; issuerAndReason: IIssuerAndReason }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.service.deleteState(payload.stateIds, payload.issuerAndReason).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IBulkResponseRecord<IState>>) =>
            StateActions.bulkDeleteStateCompleted({ payload: response.data }),
          ),
          catchError((error) => of(StateActions.fetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ payload: error }))),
    ),
  );

  validateState = createEffect(() =>
    this.actions$.pipe(
      ofType(StateActions.ActionTypes.VALIDATE_STATE),
      switchMap((payload: { stateIds: number[]; issuer: IIssuer | null }) => {
        this.store.dispatch(new AppActions.ShowLoader());

        const formattedPayload: IValidatePayload = {
          states: payload.stateIds,
        };

        return this.service.validateUtilization(formattedPayload, payload.issuer).pipe(
          tap(() => {
            this.store.dispatch(new AppActions.HideLoader());
          }),
          map((response: IGetManyResponse<IValidationResult>) =>
            StateActions.validateStateCompleted({ payload: response.data }),
          ),
          catchError((error) => of(StateActions.fetchError({ payload: error }))),
        );
      }),
      catchError((error) => of(StateActions.fetchError({ payload: error }))),
    ),
  );
}

import { forkJoin, map, Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ServiceUtilities } from '../../../shared/helper/service-utilities';
import { IGetManyResponse } from '../../../shared/model/interface/crud-response-interface.model';
import { IState } from '../states/states.model';
import { IActivityType } from '../activity-type/activity-type.model';
import { Injectable } from '@angular/core';
import { StatesService } from '../states/states.service';
import { ActivityTypeService } from '../activity-type/activity-type.service';
import { IRelevantData } from './state-rule-and-automation.model';

@Injectable({
  providedIn: 'root',
})
export class StateRuleAndAutomationService {
  constructor(
    private readonly stateService: StatesService,
    private readonly activityTypeService: ActivityTypeService,
  ) {}

  public getRelevantData(): Observable<IRelevantData> {
    const httpParams: HttpParams = ServiceUtilities.prepareGenericHttpParamsForRequest({
      page: 1,
      rowsPerPage: 5000,
      isActive: true,
      sort: {
        type: '',
        column: 'name',
      },
    });
    const body: Record<string, string | number> = ServiceUtilities.prepareGenericBodyForRequest({
      page: 1,
      rowsPerPage: 5000,
      isActive: true,
      compact: true,
      sort: {
        type: '',
        column: 'name',
      },
    });

    const observables: [Observable<IGetManyResponse<IState>>, Observable<IGetManyResponse<IActivityType>>] = [
      this.stateService.getStates(body),
      this.activityTypeService.listActivityTypes(httpParams),
    ];

    return forkJoin(observables).pipe(
      map((responseList: [IGetManyResponse<IState>, IGetManyResponse<IActivityType>]): IRelevantData => {
        return {
          states: responseList[0].data,
          activityTypes: responseList[1].data,
        };
      }),
    );
  }
}

import { createAction, props } from '@ngrx/store';
import { IAddAutomation, IAutomation, IBulkEditAutomation } from './automation.model';
import {
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { ITableQueryWithIsActive } from '../../../../shared/model/interface/common-page.model';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';

export enum ActionTypes {
  AUTOMATION_DATA_LOADING = '[AUTOMATIONS] AUTOMATION DATA LOADING',
  AUTOMATION_DATA_LOADED = '[AUTOMATIONS] AUTOMATION DATA LOADED',
  AUTOMATION_DETAIL_DATA_LOADING = '[AUTOMATIONS] AUTOMATION DETAIL DATA LOADING',
  AUTOMATION_DETAIL_DATA_LOADED = '[AUTOMATIONS] AUTOMATION DETAIL DATA LOADED',
  ADD_AUTOMATION = '[AUTOMATIONS] ADD AUTOMATION',
  ADD_AUTOMATION_COMPLETED = '[AUTOMATIONS] ADD AUTOMATION COMPLETED',
  EDIT_AUTOMATION = '[AUTOMATIONS] EDIT AUTOMATION',
  EDIT_AUTOMATION_COMPLETED = '[AUTOMATIONS] EDIT AUTOMATION COMPLETED',
  DELETE_AUTOMATION = '[AUTOMATIONS] DELETE AUTOMATION',
  DELETE_AUTOMATION_COMPLETED = '[AUTOMATIONS] DELETE AUTOMATION COMPLETED',
  BULK_EDIT_AUTOMATION = '[AUTOMATIONS] BULK EDIT AUTOMATION',
  BULK_EDIT_AUTOMATION_COMPLETED = '[AUTOMATIONS] BULK EDIT AUTOMATION COMPLETED',
  FETCH_ERROR = '[AUTOMATIONS] FETCH ERROR',
}

export const AutomationsDataLoading = createAction(
  ActionTypes.AUTOMATION_DATA_LOADING,
  props<{ tableQuery: ITableQueryWithIsActive }>(),
);

export const AutomationsDataLoaded = createAction(
  ActionTypes.AUTOMATION_DATA_LOADED,
  props<{ payload: IGetManyResponse<IAutomation> }>(),
);

export const AutomationsDetailDataLoading = createAction(
  ActionTypes.AUTOMATION_DETAIL_DATA_LOADING,
  props<{ id: number; isEdit?: boolean }>(),
);

export const AutomationsDetailDataLoaded = createAction(
  ActionTypes.AUTOMATION_DETAIL_DATA_LOADED,
  props<{ payload: IAutomation; isEdit?: boolean }>(),
);

export const AddAutomation = createAction(
  ActionTypes.ADD_AUTOMATION,
  props<{ automation: IAddAutomation; issuer: IIssuer | null }>(),
);

export const AddAutomationCompleted = createAction(
  ActionTypes.ADD_AUTOMATION_COMPLETED,
  props<{ payload: IAutomation }>(),
);

export const EditAutomation = createAction(
  ActionTypes.EDIT_AUTOMATION,
  props<{
    automation: IAddAutomation;
    id: number;
    issuer: IIssuer | null;
  }>(),
);

export const EditAutomationCompleted = createAction(
  ActionTypes.EDIT_AUTOMATION_COMPLETED,
  props<{ payload: IAutomation }>(),
);

export const DeleteAutomation = createAction(
  ActionTypes.DELETE_AUTOMATION,
  props<{ automationIds: number[]; issuerAndReason: IIssuerAndReason }>(),
);

export const DeleteAutomationCompleted = createAction(
  ActionTypes.DELETE_AUTOMATION_COMPLETED,
  props<{ payload: IBulkResponseRecord<IAutomation>[] }>(),
);

export const BulkEditAutomation = createAction(
  ActionTypes.BULK_EDIT_AUTOMATION,
  props<{ automations: IBulkEditAutomation[]; issuerAndReason: IIssuerAndReason }>(),
);

export const BulkEditAutomationCompleted = createAction(
  ActionTypes.BULK_EDIT_AUTOMATION_COMPLETED,
  props<{ payload: IBulkResponseRecord<IAutomation>[] }>(),
);

export const FetchError = createAction(ActionTypes.FETCH_ERROR, props<{ payload: object }>());

import { IFormField } from '../../../../shared/model/interface/scw-form.model';
import { ScwMatInputRule } from '../../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { ISelect, ScwMatSelectRule } from '../../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ITableQueryWithIsActive } from '../../../../shared/model/interface/common-page.model';

export declare type ModalType = 'add' | 'edit' | 'bulk-edit' | 'delete' | 'bulk-delete';

export interface IAutomationForm {
  isAllLogbooks: IFormField<boolean, []>;
  name: IFormField<string | null, ScwMatInputRule>;
  isActive: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  logbooks: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  states: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  nextStates: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  useMasterData: IFormField<ISelect<boolean, string>[] | null, ScwMatSelectRule>;
  masterDataField: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  activityTypes: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  automationType: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  approvalStepTrigger: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
  stateDuration: IFormField<number | null, ScwMatInputRule>;
  stateDurationType: IFormField<ISelect<number, string>[] | null, ScwMatSelectRule>;
}

export interface IAutomationFormRules {
  name: ScwMatInputRule[];
  isActive: ScwMatSelectRule[];
  logbooks: ScwMatSelectRule[];
  automationType: ScwMatSelectRule[];
  states: ScwMatSelectRule[];
  nextStates: ScwMatSelectRule[];
  useMasterData: ScwMatSelectRule[];
  masterDataField: ScwMatSelectRule[];
  activityTypes: ScwMatSelectRule[];
  approvalStepTrigger: ScwMatSelectRule[];
  stateDuration: ScwMatInputRule[];
  stateDurationType: ScwMatSelectRule[];
}

export interface IAutomationTableQuery extends ITableQueryWithIsActive {
  logbookIds: number[] | number;
  automationTypes: number[] | null;
}

export enum EAutomationErrorTypes {
  COLLISION = 'rules_collision_exists_cannot_save',
}

import { createAction, props } from '@ngrx/store';
import { IAddRule, IBulkEditRule, IRule } from './rule.model';
import {
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import { IRuleTableQuery } from '../../../../view/settings/state-rule-and-automation/rules/rule.model';

export enum ActionTypes {
  RULE_DATA_LOADING = '[RULES] RULE DATA LOADING',
  RULE_DATA_LOADED = '[RULES] RULE DATA LOADED',
  RULE_DETAIL_DATA_LOADING = '[RULES] RULE DETAIL DATA LOADING',
  RULE_DETAIL_DATA_LOADED = '[RULES] RULE DETAIL DATA LOADED',
  ADD_RULE = '[RULES] ADD RULE',
  ADD_RULE_COMPLETED = '[RULES] ADD RULE COMPLETED',
  EDIT_RULE = '[RULES] EDIT RULE',
  EDIT_RULE_COMPLETED = '[RULES] EDIT RULE COMPLETED',
  DELETE_RULE = '[RULES] DELETE RULE',
  DELETE_RULE_COMPLETED = '[RULES] DELETE RULE COMPLETED',
  BULK_EDIT_RULE = '[RULES] BULK EDIT RULE',
  BULK_EDIT_RULE_COMPLETED = '[RULES] BULK EDIT RULE COMPLETED',
  FETCH_ERROR = '[RULES] FETCH ERROR',
}

export const RulesDataLoading = createAction(ActionTypes.RULE_DATA_LOADING, props<{ tableQuery: IRuleTableQuery }>());

export const RulesDataLoaded = createAction(
  ActionTypes.RULE_DATA_LOADED,
  props<{ payload: IGetManyResponse<IRule> }>(),
);

export const RulesDetailDataLoading = createAction(
  ActionTypes.RULE_DETAIL_DATA_LOADING,
  props<{ id: number; isEdit?: boolean }>(),
);

export const RulesDetailDataLoaded = createAction(
  ActionTypes.RULE_DETAIL_DATA_LOADED,
  props<{ payload: IRule; isEdit?: boolean }>(),
);

export const AddRule = createAction(ActionTypes.ADD_RULE, props<{ rule: IAddRule; issuer: IIssuer | null }>());

export const AddRuleCompleted = createAction(ActionTypes.ADD_RULE_COMPLETED, props<{ payload: IRule }>());

export const EditRule = createAction(
  ActionTypes.EDIT_RULE,
  props<{
    rule: IAddRule;
    id: number;
    issuer: IIssuer | null;
  }>(),
);

export const EditRuleCompleted = createAction(ActionTypes.EDIT_RULE_COMPLETED, props<{ payload: IRule }>());

export const DeleteRule = createAction(
  ActionTypes.DELETE_RULE,
  props<{ ruleIds: number[]; issuerAndReason: IIssuerAndReason }>(),
);

export const DeleteRuleCompleted = createAction(
  ActionTypes.DELETE_RULE_COMPLETED,
  props<{ payload: IBulkResponseRecord<IRule>[] }>(),
);

export const BulkEditRule = createAction(
  ActionTypes.BULK_EDIT_RULE,
  props<{ rules: IBulkEditRule[]; issuerAndReason: IIssuerAndReason }>(),
);

export const BulkEditRuleCompleted = createAction(
  ActionTypes.BULK_EDIT_RULE_COMPLETED,
  props<{ payload: IBulkResponseRecord<IRule>[] }>(),
);

export const FetchError = createAction(ActionTypes.FETCH_ERROR, props<{ payload: object }>());

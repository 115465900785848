import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IBaseOneResponse,
  IBulkResponseRecord,
  IGetManyResponse,
} from '../../../../shared/model/interface/crud-response-interface.model';
import { IIssuer } from '../../../../shared/component/issuer/issuer.model';
import { IIssuerAndReason } from '../../../../shared/component/before-action-preparer/before-action-preparer.model';
import { IAddRule, IBulkEditRule, IRule } from './rule.model';
import { IRuleType } from '../../../../shared/component/filter/filter.class';

@Injectable({
  providedIn: 'root',
})
export class RuleService {
  private readonly routes = {
    RULES: '/rules/',
    RULE_TYPES: '/rule-types/',
    BULK: 'bulk/',
    ASSIGNED_LOGBOOKS: 'assigned-logbooks/',
  };

  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly baseUrl: string) {}

  public getRules(params: Record<string, string | number | number[]>): Observable<IGetManyResponse<IRule>> {
    return this.http.post<IGetManyResponse<IRule>>(
      `${this.baseUrl}${this.routes.RULES}`,
      { ...params },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public getRuleDetail(id: number): Observable<IBaseOneResponse<IRule>> {
    return this.http.post<IBaseOneResponse<IRule>>(
      `${this.baseUrl}${this.routes.RULES}${this.routes.ASSIGNED_LOGBOOKS}${id}`,
      null,
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
      },
    );
  }

  public addRule(rule: IAddRule, issuer: IIssuer | null): Observable<IBaseOneResponse<IRule>> {
    return this.http.post<IBaseOneResponse<IRule>>(`${this.baseUrl}${this.routes.RULES}`, {
      issuer,
      payload: rule,
    });
  }

  public editRule(rule: IAddRule, id: number, issuer: IIssuer | null): Observable<IBaseOneResponse<IRule>> {
    return this.http.put<IBaseOneResponse<IRule>>(`${this.baseUrl}${this.routes.RULES}${id}/`, {
      issuer,
      payload: rule,
    });
  }

  public deleteRule(
    ruleIds: number[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IRule>>> {
    return this.http.delete<IGetManyResponse<IBulkResponseRecord<IRule>>>(
      `${this.baseUrl}${this.routes.RULES}${this.routes.BULK}`,
      {
        body: {
          issuer: issuerAndReason.issuer,
          payload: {
            reason: issuerAndReason.reason,
            data: ruleIds,
          },
        },
      },
    );
  }

  public bulkEditRule(
    rules: IBulkEditRule[],
    issuerAndReason: IIssuerAndReason,
  ): Observable<IGetManyResponse<IBulkResponseRecord<IRule>>> {
    return this.http.put<IGetManyResponse<IBulkResponseRecord<IRule>>>(
      `${this.baseUrl}${this.routes.RULES}${this.routes.BULK}`,
      {
        issuer: issuerAndReason.issuer,
        reason: issuerAndReason.reason,
        payload: rules,
      },
    );
  }

  public getRuleTypes(): Observable<IGetManyResponse<IRuleType>> {
    return this.http.get<IGetManyResponse<IRuleType>>(`${this.baseUrl}${this.routes.RULE_TYPES}`);
  }
}
